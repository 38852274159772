import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlansComponent } from './plans/plans.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { MyvotersComponent } from './myvoters/myvoters.component';
import { VoterprofileComponent } from './voterprofile/voterprofile.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationConfirmationComponent } from './registration-confirmation/registration-confirmation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordInitComponent } from './reset-password-init/reset-password-init.component';
import { ResetPasswordRequestConfirmationComponent } from './reset-password-request-confirmation/reset-password-request-confirmation.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'terms', component: TermsOfUseComponent },
  { path: 'myvoters', component: MyvotersComponent },
  { path: 'voter/:id', component: VoterprofileComponent},
  { path: 'login', component: LoginComponent},
  { path: 'registration', component: RegistrationComponent},
  { path: 'registration-confirmation', component: RegistrationConfirmationComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'reset-password-request', component: ResetPasswordInitComponent},
  { path: 'reset-password-confirmation', component: ResetPasswordRequestConfirmationComponent}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
