import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule, MatSelectModule, MatToolbarModule, MatTableModule, MatInputModule,
MatButtonModule, MatIconModule, MatSlideToggleModule, MatCheckboxModule, MatRadioModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from "@angular/flex-layout";

import { NgModule } from '@angular/core';
import { HttpClientModule }    from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppComponent } from './app.component';
import { PlansComponent } from './plans/plans.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { AppRoutingModule } from './/app-routing.module';
import { MyvotersComponent } from './myvoters/myvoters.component';
import { VoterprofileComponent } from './voterprofile/voterprofile.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationConfirmationComponent } from './registration-confirmation/registration-confirmation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordInitComponent } from './reset-password-init/reset-password-init.component';
import { ResetPasswordRequestConfirmationComponent } from './reset-password-request-confirmation/reset-password-request-confirmation.component';

@NgModule({
  declarations: [
    AppComponent,
    PlansComponent,
    TermsOfUseComponent,
    MyvotersComponent,
    VoterprofileComponent,
    LoginComponent,
    RegistrationComponent,
    RegistrationConfirmationComponent,
    ResetPasswordComponent,
    ResetPasswordInitComponent,
    ResetPasswordRequestConfirmationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    MatListModule,
    MatSelectModule,
    MatToolbarModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    NgxSpinnerModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
