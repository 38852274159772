import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  termsOfUseAccepted: boolean = false;

  constructor() { }

  getServer(){
    return 'http://ec2-54-208-16-40.compute-1.amazonaws.com:3000';
    // if (isDevMode()) {
    //   return 'http://localhost:3000';
    // } else {
    //   return 'http://ec2-34-207-152-181.compute-1.amazonaws.com:3000';
    // }
  }
}
