export class Voter {
    pkid: number;
    firstname: string;
    lastname: string;
    age: string;
    gender: string;
    district: string;
    email: string;
    lon: number;
    lat: number;
    lonvoter: number;
    latvoter: number;
    streetaddress: string;
    phonenumber: string;
    volunteerid: number;
  }