export class MarketYear {
    constructor(
      public year: string,
      public market: string
 ) {}

    public getMarketDesc() {
      if (this.market == 'individual') {
        return 'Individual';
      } else if (this.market == 'sg'){
        return 'Small Group';
      }
    }

    public getPremiumEffectiveDate() {
      if (this.market == 'individual') {
        return 'January 1, ' + this.year;
      } else if (this.market == 'sg'){
        return 'July 1, ' + this.year;
      }
    }
  }