import { Component, OnInit } from '@angular/core';
import { PlansApiService } from '../plans-api.service';
import { GlobalsService } from '../globals.service';
import { Volunteer } from '../volunteer';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '../../../node_modules/@angular/common/http';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  constructor(private plansApiService: PlansApiService,
    public globalsService: GlobalsService,
    private router:Router,
    private spinner: NgxSpinnerService){}

  private volunteer: Volunteer = new Volunteer();
  private registrationErrors: string;

  ngOnInit() {
  }

  register(): void {
    this.spinner.show();
    this.volunteer.username = this.volunteer.email;

    this.plansApiService.postRegistration(this.volunteer)
    .subscribe(registrationResponse => {
      this.spinner.hide();

      //console.log(registrationResponse);

      if (registrationResponse && registrationResponse instanceof HttpErrorResponse) {
        console.log("It's an error");

        var error = registrationResponse;

        if (error.error.error.details && error.error.error.details.messages) {
          //console.log("We have messages.");

          if (error.error.error.details.messages.email) {
            this.registrationErrors = error.error.error.details.messages.email[0];
          } else {
            this.registrationErrors = "Registration failed.";
          }
        }

      } else {
        this.registrationErrors = null;
        this.router.navigate(['/registration-confirmation']);
      }

    },
    error => {
      this.spinner.hide();

      if (error && error.details && error.details.messages) {
        for (var i = 0; i < error.details.messages.length; i++) {
          console.log(error.details.messages[i]);
        }
      }
      
    });
  }

}
