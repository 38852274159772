export class LoginBody {

    username: string;
    password: string;


    /*
    {
        "username": "ian@dixonmobility.com",
        "password": "password01"
    }
*/
}