import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalsService } from '../globals.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent implements OnInit {

  constructor(private router:Router,
    private globalsService: GlobalsService) { }

  ngOnInit() {
  }

  termsAccepted() {
    this.globalsService.termsOfUseAccepted = true;
    this.router.navigate(['/myvoters']);
  }

}
