import { Component, ViewChild, OnInit } from '@angular/core';
import { Plan } from '../plan';
import { AgeFactor } from '../age-factor';
import { AreaRateFactor } from '../area-rate-factor';
import { PlansApiService } from '../plans-api.service';
import { PlanServiceArea } from '../plan-service-area';
import { Locality } from '../locality';
import { PlanLocality } from '../plan-locality';
import { Person } from '../person';
import { Family } from '../family';
import { MarketYear } from '../market-year';
import { MarketYears } from '../market-year-data';
import { MatTableDataSource } from '@angular/material';
import { GlobalsService } from '../globals.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {

  plans: Plan[];
  filteredPlans: Plan[];
  ageFactors: AgeFactor[];
  areaRateFactors: AreaRateFactor[];
  planServiceAreas: PlanServiceArea[];
  localities: Locality[];
  planLocalities: PlanLocality[];
  marketYearOptions: MarketYear[];

  family: Family;

  //selectedAgeFactor: AgeFactor;
  selectedLocality: Locality;
  selectedMarketYear: MarketYear;

  displayedColumns = ['plan', 'premium'];
  dataSource = new MatTableDataSource<Plan>();
  

  constructor(private plansApiService: PlansApiService,
    public globalsService: GlobalsService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
      iconRegistry.addSvgIcon('delete',
        sanitizer.bypassSecurityTrustResourceUrl('/premiumpreview/assets/delete.svg'));
     }
 
  ngOnInit() {
    this.getPlans();
    this.getAgeFactors();
    this.getAreaRateFactors();
    this.getPlanServiceAreas();
    this.getLocalities();
    this.getPlanLocalities();
    this.getMarketYearOptions();

    this.initFamily();
    this.addPerson();
  }

  initFamily() {
    this.family = new Family([]);
  }

  addPerson() {
    var person = new Person();
    person.name = 'Person ' + (this.family.members.length + 1);
    person.ageFactor = new AgeFactor();
    this.family.members.push(person);
    //console.log(this.family);
  }

  deletePerson(personIndex) {
    console.log('deletePerson: ' + personIndex);
    this.family.members.splice(personIndex, 1);
    
  }
 
  getPlans(): void {
    // this.plansApiService.getPlans()
    // .subscribe(plans => {
    //   this.plans = plans;
    // });
  }

  getAgeFactors(): void {
    // this.plansApiService.getAgeFactors()
    // .subscribe(ageFactors => this.ageFactors = ageFactors);
  }

  getAreaRateFactors(): void {
    // this.plansApiService.getAreaRateFactors()
    // .subscribe(areaRateFactors => this.areaRateFactors = areaRateFactors);
  }

  getPlanServiceAreas(): void {
    // this.plansApiService.getPlanServiceAreas()
    // .subscribe(planServiceAreas => this.planServiceAreas = planServiceAreas);
  }

  getLocalities(): void {
    // this.plansApiService.getLocalities()
    // .subscribe(localities => {
    //   this.localities = localities;
    //   this.localities.sort((a, b) => {
    //     if (a.locality > b.locality) {
    //       return 1;
    //     }
    
    //     if (a.locality < b.locality) {
    //         return -1;
    //     }
    
    //     return 0;
    //   });
    // });
  }

  getPlanLocalities(): void {
    // this.plansApiService.getPlanLocalities()
    // .subscribe(planLocalities => this.planLocalities = planLocalities);
  }

  getMarketYearOptions(): void {
    this.marketYearOptions = MarketYears;
  }

  onAgeFactorSelect(personIndex, ageIndex) {
    this.family.members[personIndex].ageFactor = this.ageFactors[ageIndex];
    
    this.buildTableDate();
  }

  onLocalitySelect(localityIndex) {
    //console.log('locality is', localityIndex)
    this.selectedLocality = this.localities[localityIndex];
    
    this.buildTableDate();
  }

  onMarketSelect(marketIndex) {
    this.selectedMarketYear = this.marketYearOptions[marketIndex];
    this.buildTableDate();
  }

  itemizeChange(event) {
    if (event.checked) {
      this.displayedColumns = ['plan', 'premium-breakdown', 'premium'];
    } else {
      this.displayedColumns = ['plan', 'premium'];
    }
  }

  buildTableDate() {
    if (this.selectedLocality && this.family.members[0].ageFactor && this.selectedMarketYear) {
      var psas = this.planServiceAreas;
      var pls = this.planLocalities;
      var arfs = this.areaRateFactors;
      var locality = this.selectedLocality;
      var marketYear = this.selectedMarketYear;
      var filteredPlans = [];

      //console.log(locality);

      this.plans.forEach(function(plan_current_value, plan_index, plan_array) {
        //console.log(plan_current_value);

        var serviceAreaMatched = false;

        psas.forEach(function(psa_current_value, psa_index, psa_array) {
          if (plan_current_value.id == psa_current_value.hiosid
              && (psa_current_value.servicearea == locality.ratingarea 
                || psa_current_value.servicearea == '0')
              && plan_current_value.year == marketYear.year
              && plan_current_value.market == marketYear.market) {

              arfs.forEach(function(arf_current, arf_index, arf_array) {
                if (arf_current.insurer == plan_current_value.insurer
                  && arf_current.planType == plan_current_value.planType
                  && arf_current.servicearea == locality.ratingarea
                  && arf_current.year == marketYear.year
                  && arf_current.market == marketYear.market) {
                    plan_current_value.arf = arf_current.factor;
                  }
              });
              serviceAreaMatched = true;
              filteredPlans.push(plan_current_value);
          }
        });

        if (!serviceAreaMatched) {
          pls.forEach(function(pls_current_value, pls_index, pls_array) {
            //console.log(pls_current_value);

            if (plan_current_value.id == pls_current_value.hiosid
                && pls_current_value.localityid === locality.id
                && plan_current_value.year == marketYear.year
                && plan_current_value.market == marketYear.market) {
  
                arfs.forEach(function(arf_current, arf_index, arf_array) {
                  if (arf_current.insurer == plan_current_value.insurer
                    && arf_current.planType == plan_current_value.planType
                    && arf_current.servicearea == locality.ratingarea
                    && arf_current.year == marketYear.year
                    && arf_current.market == marketYear.market) {
                      plan_current_value.arf = arf_current.factor;
                    }
                });
  
                filteredPlans.push(plan_current_value);
            }
          });
        }
        
      });

      filteredPlans.sort((a, b) => a.baserate * a.arf - b.baserate * b.arf);

      this.filteredPlans = filteredPlans;
      this.dataSource.data = this.filteredPlans;
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
