export class Volunteer {

    volunteerid: string;
    recipientid: string;
    firstname: string;
    lastname: string;
    phone: string;
    streetAddress: string;
    streetAddress2: string;
    city: string;
    state: string;
    zip: string;
    externaldatareference: string;
    unsubscribed: number;
    geocodestatus: number;
    geocodedlat: number;
    geocodedlon: number;
    lastmatchdate: string;
    numvoters: number;
    districtnumber: string;
    geocodedindistrict: string;
    x: string;
    username: string;
    email: string;
    emailVerified: boolean;
    password: string;

  }
    // volunteerid": 1234245,
    // "recipientid": "MLRP_esPcmQTLpa02wzH",
    // "firstname": "Ian",
    // "lastname": "Dixon",
    // "phone": "NA",
    // "streetAddress": "1147 Turnstone Drive",
    // "streetAddress2": null,
    // "city": "Charlottesville",
    // "state": "VA",
    // "zip": "22903",
    // "externaldatareference": "NA",
    // "unsubscribed": 0,
    // "geocodestatus": null,
    // "geocodedlat": "38.0017516",
    // "geocodedlon": "-78.5293038",
    // "lastmatchdate": "9/14/17 23:47",
    // "numvoters": 3,
    // "districtnumber": "State Delegate District 13",
    // "geocodedindistrict": "0",
    // "x": "NA",
    // "realm": null,
    // "username": "ian@dixonmobility.com",
    // "email": "ian@dixonmobility.com",
    // "emailVerified": true