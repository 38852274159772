import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Voter } from './voter';
import { Plan } from './plan';
import { AgeFactor } from './age-factor';
import { AreaRateFactor } from './area-rate-factor';
import { PlanServiceArea } from './plan-service-area';
import { Locality } from './locality';
import { PlanLocality } from './plan-locality';

import { GlobalsService } from './globals.service';
import { LoginResponse } from './login-response';
import { LoginBody } from './login-body';
import { Router } from '@angular/router';
import { Survey } from './survey';
import { Volunteer } from './volunteer';
import { PasswordReset } from './password-reset';
import { PasswordResetRequest } from './password-reset-request';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};



@Injectable({ providedIn: 'root' })
export class PlansApiService {

  private server = this.globalsService.getServer();

  private loginUrl = this.server + '/api/Volunteers/login';
  private myVotersUrl = this.server + '/api/Volunteers/{volunteerId}/voters';
  private voterUrl = this.server + '/api/Voters'
  private voterSurveyUrl = this.server + '/api/Voters/{voterId}/surveys'
  private surveyUrl = this.server + '/api/surveys'
  private registrationUrl = this.server + '/api/Volunteers';
  private passwordResetUrl = this.server + '/api/Volunteers/reset-password';
  private passwordResetRequestUrl = this.server + '/api/Volunteers/reset';
  private surveyReplaceOrCreateUrl = this.server + '/api/Surveys/replaceOrCreate';
  
  private plansUrl = this.server + '/api/Plans?filter[where][and][0][metal][neq]=Catastrophic';
  private ageFactorsUrl = this.server + '/api/AgeFactors';
  private areaRateFactorsUrl = this.server + '/api/GeographicFactors';
  private planServiceAreasUrl = this.server + '/api/PlanServiceAreas';
  //private localitiesUrl = this.server + '/api/ServiceAreas?filter[where][ratingarea]=2';
  private localitiesUrl = this.server + '/api/ServiceAreas';
  private planLocalitiesUrl = this.server + '/api/PlanLocalities';

  private userId;
  private accessToken = "";

  constructor(
    private http: HttpClient,
    public globalsService: GlobalsService,
    private router:Router,
    private cookieService: CookieService
  ) {

  }

  /** SET my userId */
  setSession(loginResponse: LoginResponse) {
    //console.log(loginResponse);

    this.userId = loginResponse.userId;
    this.accessToken = loginResponse.id;

    this.cookieService.set( 'userId', loginResponse.userId.toString() );
    this.cookieService.set( 'accessToken', loginResponse.id );
  }

  getAccessTokenString() {
    return "?access_token=" + this.getAccessToken();
  }

  getAccessToken() {
    if (!this.accessToken || this.accessToken === "") {
      console.log("accessToken is gone. Fetching from cookie")
      return this.cookieService.get('accessToken');
    } else {
      return this.accessToken;
    }
  }

  getUserId() {
    if (!this.userId || this.userId === "") {
      return this.cookieService.get('userId');
    } else {
      return this.userId;
    }
  }

  /** Login */
  postLogin(loginBody: LoginBody): Observable<LoginResponse> {

    return this.http.post<LoginResponse>(this.loginUrl, loginBody)
      .pipe(
        catchError(this.handleError<LoginResponse>('postLogin'))
      );
  }

  /** Registration */
  postRegistration(volunteer: Volunteer): Observable<Volunteer> {

    return this.http.post<Volunteer>(this.registrationUrl, volunteer)
      .pipe(
        catchError(this.handleError<Volunteer>('postRegistration'))
      );
  }

  /** Password Reset */
  postPasswordReset(passwordReset: PasswordReset, accessToken: string): Observable<any> {
    var urlWithToken = this.passwordResetUrl + '?access_token=' + accessToken;

    return this.http.post<any>(urlWithToken, passwordReset)
      .pipe(
        catchError(this.handleError<any>('postPasswordReset'))
      );
  }

  postPasswordResetRequest(passwordResetReqeust: PasswordResetRequest): Observable<any> {
    return this.http.post<any>(this.passwordResetRequestUrl, passwordResetReqeust)
      .pipe(
        catchError(this.handleError<any>('postPasswordResetRequest'))
      );
  }

  /** GET my voters from the server */
  getMyVoters (): Observable<Voter[]> {
    var finalUrl = this.myVotersUrl.replace('{volunteerId}',this.getUserId());
    return this.http.get<Voter[]>(finalUrl + this.getAccessTokenString())
      .pipe(
        //tap(heroes => this.log(`fetched heroes`)),
        catchError(this.handleError('getMyVoters', []))
      );
  }

  /** GET voter from the server */
  getVoter(id: number): Observable<Voter> {
    const url = `${this.voterUrl}/${id}`;
    return this.http.get<Voter>(url + this.getAccessTokenString()).pipe(
      //tap(_ => this.log(`fetched hero id=${id}`)),
      //catchError(this.handleError<Voter>(`getVoter id=${id}`))
    );
  }

  /** GET my voters from the server */
  getVoterSurveys (id: number): Observable<Survey[]> {
    var finalUrl = this.voterSurveyUrl.replace('{voterId}', id.toString());
    return this.http.get<Survey[]>(finalUrl + this.getAccessTokenString())
      .pipe(
        //tap(heroes => this.log(`fetched heroes`)),
        catchError(this.handleError('getVoterSurveys', []))
      );
  }

  /** Post Voter Survey */
  postVoterSurvey(id: number, survey: Survey): Observable<Survey> {
    var finalUrl = this.voterSurveyUrl.replace('{voterId}', id.toString());

    //var survey = new Survey();
    survey.id = id.toString() + "2018";
    survey.voterId = id;
    survey.volunteerId = this.getUserId();

    console.log("userId = " + this.getUserId());

    return this.http.post<any>(this.surveyReplaceOrCreateUrl + this.getAccessTokenString(), survey)
      .pipe(
        catchError(this.handleError<any>('postVoterSurvey'))
      );
  }

  /** GET plan by id. Return `undefined` when id not found */
  getPlanNo404<Data>(id: string): Observable<Plan> {
    const url = `${this.plansUrl}/?id=${id}`;
    return this.http.get<Plan[]>(url)
      .pipe(
        map(plans => plans[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          //this.log(`${outcome} hero id=${id}`);
        }),
        catchError(this.handleError<Plan>(`getHero id=${id}`))
      );
  }

  /** GET plan by id. Will 404 if id not found */
  getPlan(id: number): Observable<Plan> {
    const url = `${this.plansUrl}/${id}`;
    return this.http.get<Plan>(url).pipe(
      //tap(_ => this.log(`fetched hero id=${id}`)),
      catchError(this.handleError<Plan>(`getPlan id=${id}`))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      if (error.status === 401 || error.status === 403) {
        console.log(error.status + "Send to login.")
        this.router.navigate(['/login'], { queryParams: { error: "Login failed. Please try again." } });
      } else {
        return of(error);
      }

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}