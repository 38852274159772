import { Person } from './person';

export class Family {
    constructor(
        public members: Person[]
   ) {}
  
      public getTotalPremiums(rate) {
        var totalPremiums = 0.00;
        this.members.forEach(person => {
            var personPremium = 123.23454;
            personPremium  = parseFloat((person.ageFactor.factor * rate).toFixed(2));
            totalPremiums = totalPremiums + personPremium;
        });

        return totalPremiums;
      }

}