import { Component, OnInit } from '@angular/core';
import { PlansApiService } from '../plans-api.service';
import { GlobalsService } from '../globals.service';
import { LoginBody } from '../login-body';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private plansApiService: PlansApiService,
    public globalsService: GlobalsService,
    private router:Router,
    private route: ActivatedRoute){}

    private loginBody: LoginBody = new LoginBody();
    private loginFailed: boolean = false;
    
    ngOnInit() {
      this.route.queryParams
      .subscribe(params => {
        if (params.error) {
          this.loginFailed = true;
          console.log(params.error);
        }
      });
    }

    login(): void {
      this.plansApiService.postLogin(this.loginBody)
      .subscribe(loginResponse => {
        this.plansApiService.setSession(loginResponse);

        this.router.navigate(['/myvoters']);
      });
    }

    signup(): void {
      this.router.navigate(['/registration']);
    }

    resetPassword(): void {
      this.router.navigate(['/reset-password-request']);
    }

}
