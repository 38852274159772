import { Component, ViewChild, OnInit } from '@angular/core';
import { PlansApiService } from '../plans-api.service';
import { MatTableDataSource } from '@angular/material';
import { GlobalsService } from '../globals.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

import { Voter } from '../voter';

@Component({
  selector: 'app-myvoters',
  templateUrl: './myvoters.component.html',
  styleUrls: ['./myvoters.component.css']
})
export class MyvotersComponent implements OnInit {

  displayedColumns = ['voter'];
  dataSource = new MatTableDataSource<Voter>();

  voters: Voter[];

  constructor(private plansApiService: PlansApiService,
    public globalsService: GlobalsService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
      iconRegistry.addSvgIcon('delete',
        sanitizer.bypassSecurityTrustResourceUrl('/premiumpreview/assets/delete.svg'));
     }

     ngOnInit() {
      this.getMyVoters();
    }

    getMyVoters(): void {
      this.plansApiService.getMyVoters()
      .subscribe(voters => {
        //console.log(voters);
        this.voters = voters;
        this.buildTableData();
      });
    }

    buildTableData() {  
        this.dataSource.data = this.voters;
    }
}
