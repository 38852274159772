import { MarketYear } from './market-year';

export const MarketYears: MarketYear[] = [
    new MarketYear('2019', 'individual'),
    new MarketYear('2018', 'sg')
];

// export const MarketYears: MarketYear[] = [
//   { year: '2018', market: 'sg' },
//   { year: '2019', market: 'individual' }
// ];