export class Survey {

    id: string;
    timestamp: Date;
    voterId: number;
    volunteerId: string;
    q1_1: string;
    q1_2: string;
    q1_3: string;
    q1_4: string;
    q1_5: string;
    q1_6: string;
    q1_7: string;
    q1_8: string;
    q1_8_text: string = "";
    q2_1: string;
    q2_2: string;
    q3a_1: string;
    q3a_2: string;
    q3a_2_text: string = "";
    q3b_1: string;
    q3b_2: string;
    q3c_1: string;
    q3c_2: string;
    q3c_3: string;
    q3c_4: string;
    q3c_4_text: string = "";
    q4_1: string;
    q4_2: null;
    q4_3: string;
    q4_4: string;
    q4_5: string;
    q4_5_text: string = "";

}

/*
{
"timestamp": "2018-09-06T17:14:06.529Z";
"voterId": 52203;
"volunteerId": 0,
"1_1": "string",
"1_2": "string",
"1_3": "string",
"1_4": "string",
"1_5": "string",
"1_6": "string",
"1_7": "string",
"1_8": "string",
"2_1": "string",
"3a_1": "string",
"3a_2": "string",
"3b": "string",
"3c_1": "string",
"3c_2": "string",
"3c_3": "string",
"3c_4": "string",
"4_1": "string",
"4_2": null,
"4_3": "string",
"4_4": "string",
"4_5": "string",
"id": 4
}
*/