import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { PlansApiService } from '../plans-api.service';
import { GlobalsService } from '../globals.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { Voter } from '../voter';
import { Survey } from '../survey';

@Component({
  selector: 'app-voterprofile',
  templateUrl: './voterprofile.component.html',
  styleUrls: ['./voterprofile.component.css']
})
export class VoterprofileComponent implements OnInit {
  // ngDoCheck(): void {
  //   console.log("ngDoCheck");
  //       // check for object mutation
  //       if (this.survey) {
  //         console.log(this.survey);
  //         if (this.survey.q2_1) {
  //           console.log("q2_1 is true");
  //           this.survey.q2_2 = null;
  //         }
  //       }
  // }

  @Input() voter: Voter;

  constructor(
    private route: ActivatedRoute,
    private apiService: PlansApiService,
    private location: Location,
    private spinner: NgxSpinnerService,
    private router:Router
  ) {}

  private survey: Survey = new Survey();

  ngOnInit(): void {
    this.getVoter();
    this.getSurveys();
  }
  
  getVoter(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.getVoter(id)
      .subscribe(voter => this.voter = voter);
  }

  getSurveys(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.getVoterSurveys(id)
      .subscribe(surveys => {
        if (surveys[0]) {
          this.survey = surveys[0]
        } else {
          console.log("No survey in array for voter");
          this.postNewSurvey();
        }
        
      });
  }

  postNewSurvey(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.postVoterSurvey(id, new Survey())
      .subscribe(survey => this.survey = survey);
  }

  saveSurvey(): void {
    this.spinner.show();
    const id = +this.route.snapshot.paramMap.get('id');
    this.apiService.postVoterSurvey(id, this.survey)
      .subscribe(survey => {
        this.spinner.hide();
        this.survey = survey
      });
  }

  submitSurvey(): void {
    this.spinner.show();
    const id = +this.route.snapshot.paramMap.get('id');
    this.survey.timestamp = new Date();
    this.apiService.postVoterSurvey(id, this.survey)
      .subscribe(survey => {
        this.spinner.hide();
        //this.survey = survey
        this.router.navigate(['/myvoters']);
      });
  }
}
