import { Component, OnInit } from '@angular/core';
import { PlansApiService } from '../plans-api.service';
import { GlobalsService } from '../globals.service';
import { Volunteer } from '../volunteer';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PasswordReset } from '../password-reset';
import { HttpErrorResponse } from '../../../node_modules/@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private plansApiService: PlansApiService,
    public globalsService: GlobalsService,
    private router:Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute){}

    passwordReset: PasswordReset = new PasswordReset();
    messages: string;
    accessToken: string;

    ngOnInit() {
      this.route.queryParams
      .subscribe(params => {
        if (params.access_token) {
          this.accessToken = params.access_token;
          console.log(params.access_token);
        }
      });
    }

  resetPassword(): void {
    this.spinner.show();

    this.plansApiService.postPasswordReset(this.passwordReset, this.accessToken)
    .subscribe(response => {
      this.spinner.hide();

      //console.log(registrationResponse);

      if (response && response instanceof HttpErrorResponse) {
        console.log("It's an error");

        if (response.error.error.details && response.error.error.details.messages) {
          //console.log("We have messages.");

          if (response.error.error.details.messages.password) {
            this.messages = response.error.error.details.messages.password[0];
          } else {
            this.messages = "Password reset failed.";
          }
        }

      } else {
        this.messages = null;
        this.router.navigate(['/login']);
      }

    });
  }

}
