import { Component, OnInit } from '@angular/core';
import { PlansApiService } from '../plans-api.service';
import { GlobalsService } from '../globals.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PasswordResetRequest } from '../password-reset-request';
import { HttpErrorResponse } from '../../../node_modules/@angular/common/http';

@Component({
  selector: 'app-reset-password-init',
  templateUrl: './reset-password-init.component.html',
  styleUrls: ['./reset-password-init.component.css']
})
export class ResetPasswordInitComponent implements OnInit {

  constructor(private plansApiService: PlansApiService,
    public globalsService: GlobalsService,
    private router:Router,
    private spinner: NgxSpinnerService){}

    passwordResetRequest: PasswordResetRequest = new PasswordResetRequest();
    messages: string;

  ngOnInit() {
  }

  requestResetPassword(): void {
    this.spinner.show();

    this.plansApiService.postPasswordResetRequest(this.passwordResetRequest)
    .subscribe(response => {
      this.spinner.hide();

      //console.log(registrationResponse);

      if (response && response instanceof HttpErrorResponse) {
        console.log("It's an error");

        if (response.error.error.details && response.error.error.details.messages) {
          //console.log("We have messages.");

          if (response.error.error.details.messages.password) {
            this.messages = response.error.error.details.messages.password[0];
          } else {
            this.messages = "Password reset request failed.";
          }
        }

      } else {
        this.messages = null;
        this.router.navigate(['/reset-password-confirmation']);
      }

    });
  }

}
